import React from "react"
import 'css/MotulSearch.css';
import DataStore from "js/stores/DataStore";
import * as DataActions from "js/actions/DataActions";

import ProductBuyButton from "js/components/ProductBuyButton";
import ProductRow from "js/components/motul-search/ProductRow";

import buttonUp from 'gfx/button_up_dark.png';
import buttonDown from 'gfx/button_down_dark.png';


var Constants = require('js/Constants');


export default class Placement extends React.Component {

	constructor() {
		super();
		this.state = {
			productList: DataStore.currentProductList(), searchWord: "",
			isLoggedIn: DataStore.isLoggedIn(),
			visible: false,
		}
	}

	componentWillMount() {

		DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => {
			this.setState({ productList: DataStore.currentProductList() })
		});

		DataStore.on(Constants.LOGIN_STATUS_CHANGED, () => { this.setState({ isLoggedIn: DataStore.isLoggedIn() }) });
	}

	componentWillUnmount() {

		DataStore.removeListener(Constants.PRODUCT_LIST_CHANGED, () => { });
		DataStore.removeListener(Constants.LOGIN_STATUS_CHANGED, () => { });
	}

	onVisibleToggle(event) {
		this.setState({ visible: !this.state.visible });
	}




	render() {



		const placement = this.props.placement;

		let productCount = placement.placementProductCount;

		let productCountLabel = "";

		if (productCount == 1) {
			productCountLabel = "En produkt";
		} else {
			productCountLabel = productCount + " produkter";
		}

		return <div className="placement">

			<div className="placementHeader pointer" onClick={this.onVisibleToggle.bind(this)}><img className='toggle' src={this.state.visible ? buttonUp : buttonDown} />
				<h3> {placement.title} </h3>
				<b className="spacer">&nbsp;</b>
				<div className="productCount">{productCountLabel}</div>
			</div>



			{this.state.visible ? <div className="intervalList">
				{placement.intervalList && placement.intervalList.map((interval, l) => {
					return <div className="interval" key={l}>

						{interval.recommendedProductList && interval.recommendedProductList.map((recommendedProduct, m) => {
							return <div className="recommendedProduct" key={m}> <div className="motulProduct">

								{recommendedProduct.imageUrl && recommendedProduct.imageUrl.length > 10 ? <div className="motulProductImage"><img src={recommendedProduct.imageUrl} /></div> : ''}
								<div className="motulProductText">
									<h3>{recommendedProduct.recommendedProduct}</h3>
									<b>{recommendedProduct.motulDesc}</b>
									<div className="badgeList">
										{placement.title ? <div className="badgePlacement"><b>{placement.title}</b></div> : null}
										{interval.title && interval.title.includes("nitial") ? <div className="badgeIntervalInitial">{interval.title}</div> : null}
										{interval.title && !interval.title.includes("nitial") ? <div className="badgeInterval">{interval.title}</div> : null}
										{placement.capacity ? <div className="badgeInterval">{placement.capacity}</div> : null}
									</div>
								</div>
							</div>

								<table border="0">
									<thead>
										<tr>
											<th className="artnr">Artikelnummer</th>
											<th className="name">Benämning</th>
											{this.state.isLoggedIn ? <th className="stock">Lagersaldo</th> : null}
											<th className="price">Pris</th>
											{this.state.isLoggedIn ? <th className="buy"></th> : null}
										</tr>
									</thead>

									<tbody>
										{recommendedProduct.productList && recommendedProduct.productList.length > 0 ? recommendedProduct.productList.map((resultProduct, n) => {

											const product = this.state.productList.find((product) => {
												return product != null && product.id == resultProduct.productId;
											});

											if (product) {
												return <ProductRow product={product} key={n} odd={n % 2 === 0} />
											} else {
												return <tr key={n}><td colSpan="10"><div className="product">Kunde inte koppla till rätt produkt</div></td></tr>;
											}

										}) : <tr><td colSpan="10"><div className="productListEmpty"><b>Produktlista saknas, kontakta BoOve så hjälper vi dig!</b></div></td></tr>}
									</tbody>
								</table>


							</div>
						})}



					</div>
				})}
			</div>

				: ''}

		</div>
	}



}