import React from "react"
import 'css/MotulSearch.css';
import DataStore from "js/stores/DataStore";
import * as DataActions from "js/actions/DataActions";

import ProductBuyButton from "js/components/ProductBuyButton";
import dataStore from "js/stores/DataStore";
import ProductStock from "../ProductStock";



var Constants = require('js/Constants');


export default class Recommendation extends React.Component {

	constructor() {
		super();
		this.state = {

			isLoggedIn: DataStore.isLoggedIn()
		}
	}

	componentWillMount() {


		DataStore.on(Constants.LOGIN_STATUS_CHANGED, () => { this.setState({ isLoggedIn: DataStore.isLoggedIn() }) });
	}

	onProductClick() {
		/*DataActions.setCurrentProduct(null);
		DataActions.selectViewType("grid");
		DataActions.selectProductType(this.props.productType.id);
		*/

		//alert(this.props.product.id);

		DataActions.getProduct(this.props.product.id, DataStore.getLoginToken(), Constants.SELECT_PRODUCT_DONT_UPDATE_URL);

	}



	render() {

		const priceTitle = dataStore.getPriceTitle(this.props.product)

		let priceLabel = this.props.product.pricef;

		if (this.props.product.price == 0) {
			priceLabel = "Ring för pris";
		}

		return (<tr className={this.props.odd ? 'productRow odd' : 'productRow even'}  >
			<td className="artnr pointer" onClick={this.onProductClick.bind(this)} title="Klicka för mer information">{this.props.product.artnr} {this.props.index} </td>
			<td className="name pointer" onClick={this.onProductClick.bind(this)} title="Klicka för mer information">{this.props.product.name} </td>

			{this.state.isLoggedIn ? <td className="stock">	<ProductStock product={this.props.product} isLoggedIn={this.state.isLoggedIn} /> </td> : null}
			<td className="price"> {priceLabel}
			</td>
			{this.state.isLoggedIn && (!this.props.product.optionCount || this.props.product.optionCount == 0) ? <td className="buy"><ProductBuyButton product={this.props.product} /></td> : null}
			{this.state.isLoggedIn && (!this.props.product.optionCount && this.props.product.optionCount > 0) ? <td>OPTION > 0 MER INFO</td> : null}

		</tr>);









	}
}