import React from "react"


import '../../css/TopMenu.css';
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import buttonClose from '../../gfx/button_close.png';
import buttonSearch from '../../gfx/button_search.png';
import FilterMc from "./FilterMc";

var Constants = require('../Constants')

export default class FilterList extends React.Component {

	constructor() {
		super();

		this.state = {
			brand: DataStore.getBrand(),
			mc: DataStore.getMc(),
			year: DataStore.getYear(),
			category: DataStore.getCategory(),
			manufactor: DataStore.getManufactor(),
			bikeType: DataStore.getBikeType(),
			productListCount: DataStore.getFilteredProductList().length,
			searchProduct: DataStore.getSearchProduct(),
			onlyStock: DataStore.getOnlyStock(),
			onlyOutlet: DataStore.getOnlyOutlet(),
		}

		/*this.onClickDeleteBrand = this.onClickDeleteBrand.bind(this);
		this.onClickDeleteMC = this.onClickDeleteMC.bind(this);
		this.onClickDeleteYear = this.onClickDeleteYear.bind(this);
		this.onClickDeleteManufactor = this.onClickDeleteManufactor.bind(this);*/
	}



	onClickDeleteMC(event) {
		DataActions.selectMc(null);
		event.stopPropagation();
	}


	onClickMC(e) {
		//console.log(e);

		this.props.buttonPressed("MENU-A");
	}
	onClicKATEGORI(e) {
		//console.log(e);

		this.props.buttonPressed("MENU-B");
	}

	onClickDeleteYear(event) {
		DataActions.selectYear(null);
		event.stopPropagation();
	}

	onClickDeleteBrand(event) {
		DataActions.selectYear(null);
		event.stopPropagation();
	}

	onClickDeleteProductSearch(event) {
		DataActions.setSearchProductText(null);
		event.stopPropagation();
	}

	onClickDeleteManufactor(event) {
		DataActions.selectManufactor(null);
		event.stopPropagation();
	}

	onClickDeleteCategory(event) {
		DataActions.selectCategory(null);
		event.stopPropagation();
	}

	onClickDeleteBikeType(event) {
		DataActions.selectBikeType(null);
		event.stopPropagation();
	}

	onClickDeleteOnlyStock(event) {
		DataActions.setOnlyStock(false);
		event.stopPropagation();
	}

	onClickDeleteOnlyOutlet(event) {
		DataActions.setOnlyOutlet(false);
		event.stopPropagation();
	}
	componentDidMount() {

		DataStore.on(Constants.MC_CHANGED, () => { this.setup() })
		DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => { this.setup() })
		DataStore.on(Constants.YEAR_CHANGED, () => { this.setup() })
		DataStore.on(Constants.BRAND_SELECTED, () => { this.setup() })
		DataStore.on(Constants.BIKE_TYPE_SELECTED, () => { this.setup() })
		DataStore.on(Constants.SEARCH_PRODUCT_TEXT_CHANGED, () => { this.setup() })
		DataStore.on(Constants.ONLY_STOCK_CHANGED, () => { this.setup() })
		DataStore.on(Constants.ONLY_OUTLET_CHANGED, () => { this.setup() })

	}

	setup() {
		this.setState({ productListCount: DataStore.getFilteredProductList().length })
		this.setState({ brand: DataStore.getBrand() })
		this.setState({ mc: DataStore.getMc() })
		this.setState({ year: DataStore.getYear() })
		this.setState({ manufactor: DataStore.getManufactor() })
		this.setState({ category: DataStore.getCategory() })
		this.setState({ bikeType: DataStore.getBikeType() })
		this.setState({ searchProduct: DataStore.getSearchProduct() })
		this.setState({ onlyStock: DataStore.getOnlyStock() })
		this.setState({ onlyOutlet: DataStore.getOnlyOutlet() })

	}

	componentWillUnmount() {
		DataStore.removeListener(Constants.MC_CHANGED, () => { this.setup() });
		DataStore.removeListener(Constants.PRODUCT_LIST_CHANGED, () => { this.setup() });
		DataStore.removeListener(Constants.YEAR_CHANGED, () => { this.setup() });
		DataStore.removeListener(Constants.BRAND_SELECTED, () => { this.setup() });
		DataStore.removeListener(Constants.BIKE_TYPE_SELECTED, () => { this.setup() });
		DataStore.removeListener(Constants.SEARCH_PRODUCT_TEXT_CHANGED, () => { this.setup() });
		DataStore.removeListener(Constants.ONLY_STOCK_CHANGED, () => { this.setup() });
		DataStore.removeListener(Constants.ONLY_OUTLET_CHANGED, () => { this.setup() });

	}

	render() {

		return (

			<div className='filterList'>



				{this.state.searchProduct ? <div className='filter' onClick={this.onClickDeleteBrand.bind(this)} title='Ta bort söktext'><img src={buttonSearch} /> {this.state.searchProduct} <img src={buttonClose} /></div> : ""}
				{this.state.brand ? <div className='filter' onClick={this.onClickDeleteBrand.bind(this)} title='Ta bort filter: Märke'>{this.state.brand.name} <img src={buttonClose} /></div> : ""}
				{this.state.mc && false ? <div className='filter' onClick={this.onClickDeleteMC.bind(this)} title='Ta bort filter: Modell'>{this.state.mc.modellName} <img src={buttonClose} /></div> : ""}

				{this.state.mc ? <FilterMc mc={this.state.mc} year={this.state.year} /> : null}

				{this.state.year ? <div className='filter' onClick={this.onClickDeleteYear.bind(this)} title='Ta bort filter årsmodell'>{this.state.year && this.state.year.year} <img src={buttonClose} /></div> : ""}
				{this.state.category ? <div className='filter' onClick={this.onClickDeleteCategory.bind(this)} title='Ta bort filter: kategori'>{this.state.category && this.state.category.label} <img src={buttonClose} /></div> : ""}
				{this.state.manufactor ? <div className='filter' onClick={this.onClickDeleteManufactor.bind(this)} title='Ta bort filter: varumärke'>{this.state.manufactor && this.state.manufactor.name} <img src={buttonClose} /></div> : ""}
				{this.state.bikeType ? <div className='filter' onClick={this.onClickDeleteBikeType.bind(this)} title='Ta bort filter'>{this.state.bikeType && this.state.bikeType.name} <img src={buttonClose} /> </div> : ""}
				{this.state.onlyStock ? <div className='filter' onClick={this.onClickDeleteOnlyStock.bind(this)} title='Ta bort filter: endast i lager'>Endast i lager <img src={buttonClose} /> </div> : ""}
				{this.state.onlyOutlet ? <div className='filter' onClick={this.onClickDeleteOnlyOutlet.bind(this)} title='Ta bort filter: outlet'>OUTLET<img src={buttonClose} /> </div> : ""}
			</div>
		)
	}
}