import React from "react"

import '../../css/ProductDetails.css';
import * as DataActions from "../actions/DataActions"
import DataStore from "../stores/DataStore";
import buttonClose from '../../gfx/button_close_grey.png';
import arrowUp from '../../gfx/button_close.png';
import circle from '../../gfx/circle_dark.png';
import reload from '../../gfx/button_reload_dark.png';
import sampleImage from '../../gfx/sample.jpg';
import ProductOption from "./ProductOption";
import ProductBuyButton from "./ProductBuyButton";


import ProductExtraInformation from "./ProductExtraInformation";
import ProductAdmin from "./ProductAdmin";
import ProductStock from "./ProductStock";
import dataStore from "../stores/DataStore";


var Constants = require('../Constants')

export default class ProductDetails extends React.Component {

	constructor() {
		super();

		const product = DataStore.getProduct();

		this.state = {
			product: product,
			viewType: DataStore.getViewType(),
			productType: DataStore.getProductTypeString(),
			isLoggedIn: DataStore.isLoggedIn(),
			isProductLoading: DataStore.isProductLoading(product ? product.id : 0)
		}


		this.onCloseCallback = this.onCloseCallback.bind(this);


	}

	onClick(e) {

		//this.props.changeTitle("---");
	}

	onProductClick(e) {
		//alert(123);
		//this.props.changeTitle("---");
		DataActions.getProduct(null)

	}

	onHeaderClick(e) {
		DataActions.getProduct(this.state.product.id, DataStore.getLoginToken());
		this.setState({ isProductLoading: true })
		//e.preventDefault
	}

	onCloseClick(e) {

		if (this.props.pageType == Constants.PAGE_TYPE_ORDER || this.props.pageType == Constants.PAGE_TYPE_START || this.props.pageType == Constants.PAGE_TYPE_MOTUL_SEARCH) {
			DataActions.getProduct(null, null, Constants.SELECT_PRODUCT_DONT_UPDATE_URL);

		} else {
			DataActions.getProduct(null);

		}

		this.scrollTop();


	}

	scrollTop() {

		const element = document.getElementById("product");

		if (element) {
			element.scrollTop = 0;
		}

	}

	onClickImage(event) {
		//alert(event.target.src);
		DataActions.setFullsizeImage(event.target.src);

	}

	onCloseCallback() {
		//alert("Close in ProductDetails");
		//DataActions.hideProduct();
		//DataActions.getProduct(null);
		this.onCloseClick();

	}

	componentDidMount() {


		DataStore.on(Constants.SELECT_PRODUCT, () => { })
		DataStore.on(Constants.PRODUCT_DETAILS_HIDE_SPINNER, () => { this.setState({ isProductLoading: false }) })

		DataStore.on(Constants.REQUEST_COUNT_CHANGED, () => { })
		DataStore.on(Constants.LOGIN_STATUS_CHANGED, () => {
			this.setState({ isLoggedIn: DataStore.isLoggedIn() });

			//alert(this.state.isLoggedIn);

			/*if (this.state.product > 0){
					DataActions.getProduct(this.state.product.id, DataStore.getLoginToken());
			}*/

		});
		DataStore.on(Constants.PRODUCT_CHANGED, () => {

			const newProduct = DataStore.getProduct();

			if (this.state.product == null) {
				this.setState({ isProductLoading: true });
			}

			if (this.state.product && newProduct && this.state.product.id != newProduct.id) {
				this.setState({ isProductLoading: true });
			}

			if (this.state.product && newProduct && this.state.product.id == newProduct.id) {
				this.setState({ isProductLoading: false });
			}

			//alert("CHANGED " + (this.state.product ? this.state.product.id : 0) + " => " + DataStore.getProduct().id); 


			this.setState({ product: DataStore.getProduct() })
			//this.setState({  isProductLoading:DataStore.isProductLoading(this.state.product ? this.state.product.id : 0)})
		})

		//window.scrollTo(0, 0);
		//alert("mount");
	}






	render() {

		//console.log(this.props);

		//this.props.changeTitle('HeaderTitle');

		//const baseUrl = "http://public.boove.se/w2image/"
		const baseUrl = DataStore.getImageUrl();
		var startImage = null;

		if (this.state.product && this.state.product.image) {
			startImage = baseUrl + this.state.product.image
		}

		const css = {}

		const priceTitle = dataStore.getPriceTitle(this.state.product)
		return (



			<div className='productDetails' >

				<div className={this.state.product ? "product" : "productHidden"} id='product' >

					{this.state.product ?
						<div>
							<div className='close'>
								<img src={buttonClose} className='closeButton pointer' title='Stäng' onClick={this.onCloseClick.bind(this)} />
							</div>





							<div className='productDetails'>


								<div className='detailsHeader' onClick={this.onHeaderClick.bind(this)}>
									{this.state.isProductLoading ? <img src={reload} className='spinning' /> : <img src={reload} />}
									<h3 className='manufactor' > {this.state.product.manufactorName} {this.state.productRequestCount} </h3>

								</div>
								<h1 className='productName'>{this.state.product.name} </h1><br />
								<h3 className='productArtnr'>Artnr: {this.state.product.artnr}</h3><br />
								<h3 className={this.state.product.outlet ? 'productPrice outlet' : 'productPrice'} title={priceTitle}>Pris: {this.state.product.pricef} </h3><br />


								{this.state.isLoggedIn && this.state.product.optionCount == 0 ? <ProductBuyButton productOptionSetId={null} product={this.state.product} selectedProduct={true} /> : null}
								{this.state.isLoggedIn && this.state.product.optionCount == 0 ? <ProductStock product={this.state.product} isLoggedIn={this.state.isLoggedIn} /> : null}

								{this.state.product.productOption ? <ProductOption product={this.state.product} isLoggedIn={this.state.isLoggedIn} /> : null}

								{this.state.product.ean && this.state.product.ean.length > 0 && false ? <h3 className='productEan'>EAN: {this.state.product.ean}<br /></h3> : null}
								{this.state.product.weight && this.state.product.weight.length > 0 ? <h3 className='productWeight'>Vikt: {this.state.product.weight}<br /></h3> : null}


								{this.state.product.adm ? <ProductAdmin product={this.state.product} /> : null}


							</div>



							<div className='productImages'>
								<div className='startImage'>

									<img src={startImage} onClick={this.onClickImage.bind(this)} className='pointer' title='Klicka för större bild' ></img>
								</div>

								<div className='imageList'>

									{this.state.product.images && this.state.product.images.map((item, index) => (
										<div className='imageItem'><img src={baseUrl + item} key={item.id} className='pointer' title='Klicka för större bild' onClick={this.onClickImage.bind(this)}></img></div>))}


								</div>
							</div>




							<ProductExtraInformation product={this.state.product} onCloseCallback={this.onCloseCallback} />

							<div className='closeBottom' onClick={this.onCloseClick.bind(this)}>
								<img src={arrowUp} className='pointer' title='Stäng' />
							</div>

						</div>
						: <div></div>}


				</div>


			</div>

		)
	}
}