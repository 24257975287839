import React from "react"
import 'css/MotulSearch.css';
import DataStore from "js/stores/DataStore";
import * as DataActions from "js/actions/DataActions";
import Recommendation from "js/components/motul-search/Recommendation";
import ModelSection from "js/components/motul-search/ModelSection";

import buttonReload from 'gfx/button_reload.png';

import buttonUp from 'gfx/button_up.png';
import buttonDown from 'gfx/button_down.png';
import buttonClose from 'gfx/button_close_dark.png';

var Constants = require('js/Constants');


export default class MotulSearch extends React.Component {

	constructor() {
		super();
		this.state = {

			regnr: "",
			makeType: "0",
			make: "",
			model: "",
			type: "",
			search: "",
			motulSearchModel: null,
			spinnerForm: false,
			spinnerRegnr: false,
			spinnerSearch: false,
			showRegnr: true,
			showForm: false,
			showSearch: false
		}
	}

	setup() {
		this.setState({ motulSearchModel: DataStore.getMotulSearchModel() })
		this.setState({ spinnerForm: false, spinnerRegnr: false, spinnerSearch: false });
	}
	onRegnrChange(event) {

		const value = event.target.value

		this.setState({ regnr: value });
	}

	onMakeChange(event) {

		const value = event.target.value

		this.setState({ make: value });
	}

	onMakeTypeListChange(event) {
		console.log("FP: onMakeTypeListChange: 1: ", event.target.value)
		this.setState({ makeType: event.target.value, make: "", model: "", type: "" }, this.searchVehicle);
	}

	onMakeListChange(event) {
		if (event.target.value == 0) {
			return;
		}

		this.setState({ make: event.target.value, model: "", type: "" }, this.searchVehicle);
	}

	onModelListChange(event) {
		if (event.target.value == 0) {
			return;
		}
		this.setState({ model: event.target.value, type: "" }, this.searchVehicle);
	}

	onTypeListChange(event) {
		if (event.target.value == 0) {
			return;
		}
		this.setState({ type: event.target.value }, this.searchVehicle);

	}

	onSearchChange(event) {
		this.setState({ search: event.target.value });

	}

	onResetRegnrPressed(event) {
		this.setState({ regnr: '' });
	}

	onResetSearchPressed(event) {
		this.setState({ search: '' });
	}

	onRecommendationListChange(event) {
		if (event.target.value == 0) {
			return;
		}
		this.setState({ spinnerSearch: true });
		DataActions.motulSearch("search", { regnr: "", makeType: "", make: "", model: "", type: event.target.value, search: this.state.search });
	}

	clearSearchField() {
		this.setState({ search: "" })

	}

	searchVehicle() {
		/*if (!this.state.regnr || this.state.regnr.length != 6) {
			alert("Felaktigt format på regnummer.");
			return;

		}*/

		if (this.state.makeType == 0) {
			return;
		}
		console.log("FP: onMakeTypeListChange: 2: ", this.state.makeType)

		this.setState({ spinnerForm: true });

		DataActions.motulSearch("search-vehicle", { regnr: "", makeType: this.state.makeType, make: this.state.make, model: this.state.model, type: this.state.type });
	}

	searchRegnrClick() {

		if (!this.state.regnr || this.state.regnr.length != 6) {
			alert("Felaktigt format på regnummer.");
			return;
		}

		this.setState({ makeType: "0" });

		this.setState({ spinnerRegnr: true });
		DataActions.motulSearch("search-regnr", { regnr: this.state.regnr, makeType: "", make: "", model: "", type: "", search: "" });
	}

	searchClick() {
		this.setState({ makeType: "0" });
		this.setState({ spinnerSearch: true });
		DataActions.motulSearch("search", { regnr: "", makeType: "", make: "", model: "", type: "", search: this.state.search });
	}

	onToggleRegnr(event) {
		this.setState({ showRegnr: !this.state.showRegnr });
	}

	onToggleForm(event) {
		this.setState({ showForm: !this.state.showForm });
	}

	onToggleSearch(event) {
		this.setState({ showSearch: !this.state.showSearch });
	}

	componentDidMount() {

		DataStore.on(Constants.MOTUL_CHANGED, () => {

			this.setup();

			if (this.state.motulSearchModel && this.state.motulSearchModel && this.state.motulSearchModel.noRecommendationFound === true) {
				alert("Hittade inga passande produkter. Kontakta BoOve så hjälper vi dig!")
			}

		})

		this.setup();


	}

	componentWillUnmount() {
		DataStore.removeListener(Constants.MOTUL_CHANGED, () => { });
	}




	render() {


		return (<div className="motulSearch">
			<h1>Sök passande motul-produkter</h1><br />
			<div className="section">
				<div className="sectionHeader" onClick={this.onToggleRegnr.bind(this)} >	<img className='toggle' src={this.state.showRegnr ? buttonUp : buttonDown} /> <h3>Sök på regnummer (endast bil)  </h3> {this.state.spinnerRegnr ? <img className='spinner' src={buttonReload} /> : ''}</div>
				{this.state.showRegnr ? <div className="search">

					<div className="searchBoxWrapper">
						<input type='text' className='searchBox' value={this.state.regnr} onChange={this.onRegnrChange.bind(this)} placeholder='Regnummer' onKeyPress={event => {
							if (event.key === 'Enter') {
								this.searchRegnrClick()
							}
						}} />
						<img className='reset' src={buttonClose} onClick={this.onResetRegnrPressed.bind(this)} />
					</div>

					<div className="button pointer" onClick={this.searchRegnrClick.bind(this)}>SÖK</div>




				</div> : ''}
			</div>

			<div className="section">
				<div className="sectionHeader" onClick={this.onToggleForm.bind(this)}>	<img className='toggle' src={this.state.showForm ? buttonUp : buttonDown} /> <h3>Välj fordon från lista </h3> {this.state.spinnerForm ? <img className='spinner' src={buttonReload} /> : ''}</div>
				{this.state.showForm ? <div>
					<select onChange={this.onMakeTypeListChange.bind(this)} defaultValue="0">
						<option value="0">Välj fordonstyp</option>
						<option value="4">MC</option>
						<option value="1">BIL</option>

					</select>

					{this.state.motulSearchModel && this.state.motulSearchModel && this.state.motulSearchModel.makeList
						&& this.state.motulSearchModel.makeList.length > 0 ? <select onChange={this.onMakeListChange.bind(this)} >
							<option value="0">Välj tillverkare</option>
							{this.state.motulSearchModel.makeList.map((make, i) => {
								return <option key={i} value={make.value}>{make.make}</option>
							})}

						</select> : null}

					{this.state.motulSearchModel && this.state.motulSearchModel && this.state.motulSearchModel.modelList
						&& this.state.motulSearchModel.modelList.length > 0 ? <select onChange={this.onModelListChange.bind(this)} >
							<option value="0">Välj modell-familj</option>
							{this.state.motulSearchModel.modelList.map((model, i) => {
								return <option key={i} value={model.value}>{model.modelYear}</option>
							})}

						</select> : null}


					{this.state.motulSearchModel && this.state.motulSearchModel && this.state.motulSearchModel.typeList
						&& this.state.motulSearchModel.typeList.length > 0 ? <select onChange={this.onTypeListChange.bind(this)} >
							<option value="0">Välj modell/årsmodell</option>
							{this.state.motulSearchModel.typeList.map((type, i) => {
								return <option key={i} value={type.recommendation}>{type.type}</option>
							})}

						</select> : null}
				</div> : ''}
			</div>

			<div className="section">
				<div className="sectionHeader" onClick={this.onToggleSearch.bind(this)}>
					<img className='toggle' src={this.state.showSearch ? buttonUp : buttonDown} />
					<h3>Fritextsök efter fordon </h3> {this.state.spinnerSearch ? <img className='spinner' src={buttonReload} /> : ''}
				</div>

				{this.state.showSearch ? <div className="search">

					<div className="searchBoxWrapper">
						<input type='text' className='searchBox' value={this.state.search} onChange={this.onSearchChange.bind(this)} placeholder='Sök...' onKeyPress={event => {
							if (event.key === 'Enter') {
								this.searchClick()
							}
						}} />
						<img className='reset' src={buttonClose} onClick={this.onResetSearchPressed.bind(this)} />
					</div>


					<div className="button pointer" onClick={this.searchClick.bind(this)}>SÖK</div>




				</div> : ''}
				{this.state.motulSearchModel && this.state.motulSearchModel && this.state.motulSearchModel.recommendationList && this.state.motulSearchModel.recommendationList.length > 0 ?
					<select className="recommendationList" onChange={this.onRecommendationListChange.bind(this)} >
						<option value="0">Välj modell/årsmodell</option>
						{this.state.motulSearchModel && this.state.motulSearchModel && this.state.motulSearchModel.recommendationList && this.state.motulSearchModel.recommendationList.map((recommendation, i) => {
							return <option key={i} value={recommendation.recommendation}>{recommendation.type}</option>
						})}

					</select> : ''}
			</div>





			{this.state.motulSearchModel && this.state.motulSearchModel.recommendation ?
				<div className="modelSectionHeader result"><h1>Rekommenderade produkter</h1><div><Recommendation recommendation={this.state.motulSearchModel.recommendation} /></div></div> : null}


			{this.state.motulSearchModel && this.state.motulSearchModel.resultRegnr
				&& this.state.motulSearchModel.resultRegnr.modelList ?
				<h3>
					{this.state.motulSearchModel.resultRegnr.modelList.length == 0 ? 'Hittade inte passade bilmodeller' : ''}
					{this.state.motulSearchModel.resultRegnr.modelList.length == 1 ? 'Hittade en passade bilmodell' : ''}
					{this.state.motulSearchModel.resultRegnr.modelList.length > 1 ? 'Hittade ' + this.state.motulSearchModel.resultRegnr.modelList.length + ' passade bilmodeller' : ''}
				</h3>
				: ''}

			{

				this.state.motulSearchModel && this.state.motulSearchModel.resultRegnr && this.state.motulSearchModel.resultRegnr.modelList
				&& this.state.motulSearchModel.resultRegnr.modelList.map((model, i) => {





					/*if (resellerLetter != c){
							l = resellerLetter
							c = resellerLetter
					}else{
	
					}*/


					return <ModelSection model={model} key={i} />
				})
			}


		</div >)
	}
}