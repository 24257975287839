import React from "react"
import 'css/SlideList.css';

import { NavLink } from 'react-router-dom'



export default class Slide extends React.Component {

	constructor() {
		super();
	}

	isValidJson(string) {
		try {
			JSON.parse(string);
		} catch (e) {
			console.log("FP: Not valid json: ", string);
			return false;
		}
		return true;
	}

	render() {

		const slide = this.props.slide;

		let css = {};

		// 

		// css
		if (slide.cssJson && this.isValidJson(slide.cssJson)) {
			slide.css = JSON.parse(slide.cssJson);
		} else {
			slide.css = {};
		}

		// imagelist
		if (slide.imageListJson && this.isValidJson(slide.imageListJson)) {
			slide.imageList = JSON.parse(slide.imageListJson);
		} else {
			slide.imageList = [{}];
		}

		// readMore
		if (slide.readMoreJson && this.isValidJson(slide.readMoreJson)) {
			slide.readMore = JSON.parse(slide.readMoreJson);
		} else {
			slide.readMore = {};
		}

		// klart

		if (slide.css.backgroundImage) {
			css.backgroundImage = slide.css.backgroundImage
		}

		if (slide.css.backgroundSize) {
			css.backgroundSize = slide.css.backgroundSize
		}

		// background tint
		let className = " ";

		if (slide && slide.css && slide.css.backgroundTint > 0) {
			className = className + " backgroundTint" + slide.css.backgroundTint
		}

		const image = slide.imageList && slide.imageList.length > 0 ? slide.imageList[0] : null; // använder bara första bilden. 

		const readMoreLabel = slide.readMore && slide.readMore.label && slide.readMore.label != "" ? slide.readMore.label : "Läs mer";
		const readMoreTarget = slide.readMore && slide.readMore.target && slide.readMore.target != "" ? slide.readMore.target : '_self';

		var readMore = slide.readMore && slide.readMore.href ? <div id="readMore"> {slide.readMore.href.startsWith("/") ? <NavLink to={slide.readMore.href} title={'Läs mer på: ' + slide.readMore.href}>{readMoreLabel}</NavLink> : <a href={slide.readMore.href} title={'Läs mer på: ' + slide.readMore.href} target={readMoreTarget}>{readMoreLabel}</a>}</div> : null;



		const contentDesktop = <div className="contentDesktop">
			<h1>{slide.title}</h1>
			<p dangerouslySetInnerHTML={{ __html: slide.html }}></p>
			{readMore}
		</div>

		const contentMobile = <div className="contentMobile">

			{image ? <img src={image.src} /> : ''}
			<p dangerouslySetInnerHTML={{ __html: slide.html }}></p>

		</div>

		let desktop = null;
		let mobile = <div id="mobile"><h1>{slide.title}</h1>{contentMobile}{readMore}</div>



		if (image) {
			desktop = <div id="desktop" className="image"><div className="contentDesktopWrapper">{contentDesktop}</div><div><img src={image.src} /></div></div>
		} else {
			desktop = <div id="desktop">{contentDesktop} </div>
		}
		return (

			<div className={"slide " + className} style={css} >

				{desktop}
				{mobile}

			</div>
		)
	}
}