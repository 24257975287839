import React from "react"

import Header from "./Header";
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions"
import TopMenu from "./TopMenu";

import SideMenu from "./SideMenu";
import DropMenuMC from "./DropMenuMC";
import DropMenuCategory from "./DropMenuCategory";
import DropMenuManufactor from "./DropMenuManufactor";
import DropMenuBikeType from "./DropMenuBikeType";
import ProductGrid from "./ProductGrid";
import ProductList from "./ProductList";
import ProductDetails from "./ProductDetails";
import SearchMenu from "./SearchMenu";
//import SearchMenu from "./SearchResult";
import Overlay from "./Overlay";
import FilterList from "./FilterList"
import Customer from "./Customer"

import News from './News.js'


import { BrowserRouter as Router } from 'react-router-dom'
import Route from 'react-router-dom/Route'
import Switch from 'react-router-dom/Switch'


import SearchResult from "./SearchResult";
import OrderList from "./OrderList";
import FindResellerList from "./www/FindResellerList";
import FindBrandGrid from "./www/FindBrandGrid";
import ProductResult from "./ProductResult";
import Test from "./Test";
import Help from "./Help";

import SlideList from "js/components/slide/SlideList";
import MotulSearch from "js/components/motul-search/MotulSearch";

import WebPage from "./WebPage";

var Constants = require('../Constants')

export default class Layout extends React.Component {

	constructor() {
		super();

		this.state = {
			sideMenuVisible: false,
			menuMCVisible: false,
			menuCateogryVisible: false,
			menuManufactorVisible: false,
			menuBikeTypeVisible: false,
			productType: 0,
			requestCount: 0,
			product: DataStore.getProduct()



		}

		this.getProductList = this.getProductList.bind(this);

	}



	mcListChange(value) {
		console.log("mcListChange");
	}

	componentDidMount() {

		DataStore.on(Constants.REQUEST_COUNT_CHANGED, () => {
			this.setState({ requestCount: DataStore.getRequestCount() })
		})

		DataStore.on(Constants.PRODUCT_CHANGED, () => { // FP: ta bort denna om vi inte ska visa produkt på layout-nivå
			this.setState({ product: DataStore.getProduct() })
		})


		DataStore.on(Constants.DROP_MENU_VISIBILITY_CHANGED, () => {
			//alert(DataStore.isDropmenuMcVisible())
			this.setState({ menuMCVisible: DataStore.isDropmenuMcVisible() })
			this.setState({ menuCategoryVisible: DataStore.isDropmenuCategoryVisible() })
			this.setState({ menuManufactorVisible: DataStore.isDropmenuManufactorVisible() })
			this.setState({ menuBikeTypeVisible: DataStore.isDropmenuBikeTypeVisible() })
		})

		DataStore.on(Constants.SIDE_MENU_MC_VISIBILITY_CHANGED, () => {


			/*var newSideMenuVisible = !this.state.sideMenuVisible;

			this.setState({
					sideMenuVisible: newSideMenuVisible
			});*/

			this.setState({ sideMenuVisible: DataStore.isSideMenuVisible() })

			if (DataStore.isSideMenuVisible() === true) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'scroll';
			}



		})


		// hämta datamodeller
		DataActions.getStartModel();
		/*DataActions.getProductList(DataStore.getLoginToken());
		DataActions.getBaseModel(); */
		//DataActions.getProduct(97673);
		let tenMinutes = 60 * 1000 * 10;

		if (process.env.NODE_ENV == "development") {
			tenMinutes = 60 * 1000 // = var 60:e sekund
		}

		setInterval(() => {

			if (DataStore.getLoginToken()) {
				DataActions.hasLoginTokenExpired(DataStore.getLoginToken());
			}

		}, tenMinutes); // kolla inloggning en gång var 10:e minut, (60 * 1000 * 10); // 60 * 100 = var 6:e sekund


		let oneHour = (60 * 1000 * 60)

		if (process.env.NODE_ENV == "development") {
			oneHour = 60 * 1000 * 10 // = var tionde minut
		}

		setInterval(() => {

			if (DataStore.hasDataExpired()) {
				DataActions.getProductList(DataStore.getLoginToken(), false);
				DataActions.getBaseModel();
			}

		}, oneHour); // kolla data en gång i timmen (60 * 1000 * 60)

	}

	spinner = {
		background: 'orange', position: 'fixed', zIndex: '100', width: '1200px', height: '30px', textAlign: 'center'

	}


	getProductList(match, viewType, productIdFromUrl) {
		return <div>

			<Header productType={match.params.productType} viewType={viewType} sideMenuButton={true} productIdFromUrl={productIdFromUrl} />

			<TopMenu />
			<DropMenuBikeType visible={this.state.menuBikeTypeVisible} />
			<DropMenuMC visible={this.state.menuMCVisible} />
			<DropMenuCategory visible={this.state.menuCategoryVisible} />
			<DropMenuManufactor visible={this.state.menuManufactorVisible} />
			<SideMenu visible={this.state.sideMenuVisible} />
			<Overlay visible={this.state.sideMenuVisible} />
			<SearchMenu />
			<FilterList />
			<SearchResult viewType={viewType} />

			<ProductResult viewType={viewType} />



		</div>
	}

	getProductType(productTypeLabel) {

		if (productTypeLabel.toLowerCase() == "mc") { return 1 }
		if (productTypeLabel.toLowerCase() == "cykel") { return 2 }
		if (productTypeLabel.toLowerCase() == "motul") { return 3 }

		return 0;

	}

	render() {



		return (

			<Router>

				<div className='layout'>

					<Switch>


						<Route path="/test" exact render={({ match }) => (
							<div>

								<Test />


							</div>
						)} />


						<Route path="/help" exact render={({ match }) => (
							<div>

								<Help />


							</div>
						)} />

						<Route path="/bli-af" exact render={({ match }) => (
							<div>
								<Header hideMenu='true' sideMenuButton={false} pageType={Constants.PAGE_TYPE_START} />
								<WebPage pageKey="W2_PUBLIC_BLI_AF" />
							</div>
						)} />

						<Route path="/customer" exact render={({ match }) => (
							<div>

								<Header productType={match.params.productType} />
								<Customer />


							</div>
						)} />


						<Route path="/order" render={({ match }) => (
							<div>

								<Header productType={match.params.productType} pageType={Constants.PAGE_TYPE_ORDER} />
								<OrderList />


							</div>
						)} />

						<Route path="/" exact render={() => (

							<div className='page'>

								<Header hideMenu='true' sideMenuButton={false} pageType={Constants.PAGE_TYPE_START} />
								<News requestCount={this.state.requestCount} productType={null} />

							</div>
						)} />




						<Route path="/mc" exact render={({ match }) => (
							<div>
								<Header hideMenu='true' sideMenuButton={false} pageType={Constants.PAGE_TYPE_START} />
								<News requestCount={this.state.requestCount} productType='1' />
							</div>
						)} />


						<Route path="/cykel" exact render={({ match }) => (
							<div>
								<Header hideMenu='true' sideMenuButton={false} pageType={Constants.PAGE_TYPE_START} />
								<News requestCount={this.state.requestCount} productType='2' />
							</div>
						)} />


						<Route path="/motul" exact render={({ match }) => (
							<div>
								<Header hideMenu='true' sideMenuButton={false} pageType={Constants.PAGE_TYPE_START} />
								<News requestCount={this.state.requestCount} productType='3' />
							</div>
						)} />


						<Route path="/butiker/:resellerType?" render={({ match }) => (
							<div>
								<Header hideMenu='true' sideMenuButton={false} pageType={Constants.PAGE_TYPE_START} />
								<FindResellerList resellerType={match.params.resellerType ? match.params.resellerType.toLowerCase() : null} />
							</div>
						)} />

						<Route path="/varumarken" render={({ match }) => (
							<div>
								<Header hideMenu='true' sideMenuButton={false} pageType={Constants.PAGE_TYPE_START} />
								<FindBrandGrid />
							</div>
						)} />

						<Route path="/slide-list" render={({ match }) => (
							<div>
								<SlideList />
							</div>
						)} />


						<Route path="/motul/search" render={({ match }) => (
							<div>
								<Header productType="motul" pageType={Constants.PAGE_TYPE_MOTUL_SEARCH} />
								<MotulSearch />
							</div>
						)} />


						<Route path="/:productType/products/:viewType" render={({ match }) => {

							//DataActions.setAutoNavigateTo("manufactor", 7);
							//DataStore.setAutoNavigateTo("manufactor", "titax");

							return this.getProductList(match, match.params.viewType, null);

						}} />


						<Route path="/:productType/varumarke/:key" render={({ match }) => {

							//DataActions.setAutoNavigateTo("manufactor", 7);
							DataStore.setAutoNavigateTo("manufactor", match.params.key);

							return this.getProductList(match, "list", null);

						}} />

						<Route path="/:productType/:key1?/:key2?/:key3?/:key4?/:key5?/:key6?/:key7?/:key8?/:key9?/:key10?" render={({ match }) => {

							//DataActions.setAutoNavigateTo("manufactor", 7);
							//DataStore.setAutoNavigateTo("manufactor", match.params.key); cross-delar-plast-mx
							//(match.params.key2)
							//alert(match.params.key2);
							var list = [];

							if (match.params.key1) { list.push(match.params.key1); }
							if (match.params.key2) { list.push(match.params.key2); }
							if (match.params.key3) { list.push(match.params.key3); }
							if (match.params.key4) { list.push(match.params.key4); }
							if (match.params.key5) { list.push(match.params.key5); }
							if (match.params.key6) { list.push(match.params.key6); }
							if (match.params.key7) { list.push(match.params.key7); }
							if (match.params.key8) { list.push(match.params.key8); }
							if (match.params.key9) { list.push(match.params.key9); }
							if (match.params.key10) { list.push(match.params.key10); }



							//DataStore.removeAllFilters();
							DataStore.setAutoNavigateToList(list);



							var viewType = "list";

							if (match.params.key1 == "list" || match.params.key1 == "grid") {
								viewType = match.params.key1;
							}

							DataStore.setViewType(viewType);

							const newProductType = this.getProductType(match.params.productType)
							// alert(newProductType);
							DataStore.setProductType(newProductType);

							// hantera visa en särskild produkt

							var productIdFromUrl = null;

							if (parseInt(match.params.key2) > 0 && !match.params.key2.includes("-")) {
								productIdFromUrl = parseInt(match.params.key2)

							}





							//DataStore.filterProductList();

							/*if (DataStore.getProductType() != newProductType){
									DataStore.setProductType(newProductType);
									DataStore.removeAllFilters();
							}*/

							//DataStore.autoNavigateTo();


							//alert("layout");


							return this.getProductList(match, viewType, productIdFromUrl);

						}} />


					</Switch>

				</div>

			</Router>


		)
	}
}
