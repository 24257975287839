import React from "react"
import 'css/MotulSearch.css';
import Recommendation from "js/components/motul-search/Recommendation";
import buttonUp from 'gfx/button_up_dark.png';
import buttonDown from 'gfx/button_down_dark.png';

export default class ModelSection extends React.Component {

	constructor() {
		super();
		this.state = {

			visible: false

		}
	}


	onVisibleToggle(event) {
		this.setState({ visible: !this.state.visible });
	}




	render() {

		return <div className="modelSectionHeader result">
			<div className="model pointer" onClick={this.onVisibleToggle.bind(this)}><img className='toggle' src={this.state.visible ? buttonUp : buttonDown} /><h3> {this.props.model.model} </h3></div>
			{
				this.state.visible ? <div className="">
					{this.props.model.recommendationList && this.props.model.recommendationList.map((recommendation, i) => {
						return <Recommendation recommendation={recommendation} key={i} />
					})}
				</div> : ''
			}
		</div >

	}
}