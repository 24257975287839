import React from "react"
import 'css/MotulSearch.css';
import DataStore from "js/stores/DataStore";
import * as DataActions from "js/actions/DataActions";

import ProductBuyButton from "js/components/ProductBuyButton";
import ProductRow from "js/components/motul-search/ProductRow";
import Placement from "js/components/motul-search/Placement";


var Constants = require('js/Constants');


export default class Recommendation extends React.Component {





	render() {


		return (<div className="recommendation">



			<div>
				<br />
				{this.props.recommendation.placementList && this.props.recommendation.placementList.map((placement, i) => {
					return <Placement placement={placement} key={i} />
				})}

			</div>



		</div>)
	}
}