module.exports = {
	DROP_MENU_MC_VISIBILITY: 'DROP_MENU_MC_VISIBILITY',
	//DROP_MENU_MC_VISIBILITY_CHANGED: 'DROP_MENU_MC_VISIBILITY_CHANGED',
	DROP_MENU_MC_TOGGLE: 'DROP_MENU_MC_TOGGLE',
	DROP_MENU_BIKE_TYPE_VISIBILITY: 'DROP_MENU_BIKE_TYPE_VISIBILITY',

	DROP_MENU_CATEGORY_VISIBILITY: 'DROP_MENU_CATEGORY_VISIBILITY',
	//DROP_MENU_CATEGORY_VISIBILITY_CHANGED: 'DROP_MENU_CATEGORY_VISIBILITY_CHANGED',
	DROP_MENU_CATEGORY_TOGGLE: 'DROP_MENU_CATEGORY_TOGGLE',

	DROP_MENU_VISIBILITY_CHANGED: 'DROP_MENU_VISIBILITY_CHANGED',
	LOGIN_VISIBILITY: 'LOGIN_VISIBILITY',
	LOGIN_VISIBILITY_CHANGED: 'LOGIN_VISIBILITY_CHANGED',

	SIDE_MENU_CATEGORY_VISIBILITY: 'SIDE_MENU_CATEGORY_VISIBILITY',
	SIDE_MENU_TOGGLE: 'SIDE_MENU_TOGGLE',
	SIDE_MENU_MC_VISIBILITY_CHANGED: 'SIDE_MENU_MC_VISIBILITY_CHANGED',

	SELECT_BRAND: 'SELECT_BRAND',
	BRAND_SELECTED: 'BRAND_SELECTED',
	SET_SEARCH_CATEGORY_TEXT: 'SET_SEARCH_CATEGORY_TEXT',
	SEARCH_CATEGORY_TEXT_CHANGED: 'SEARCH_CATEGORY_TEXT_CHANGED',

	CATEGORY_LIST_CHANGED: 'CATEGORY_LIST_CHANGED',
	SELECT_CATEGORY: 'SELECT_CATEGORY',
	SELECT_CATEGORY_GRID: 'SELECT_CATEGORY_GRID',
	CATEGORY_SELECTED: 'CATEGORY_SELECTED',

	SEARCH_MANUFACTOR_TEXT_CHANGED: 'SEARCH_MANUFACTOR_TEXT_CHANGED',
	SELECT_MANUFACTOR: 'SELECT_MANUFACTOR',
	MANUFACTOR_SELECTED: 'MANUFACTOR_SELECTED',
	MANUFACTOR_LIST_CHANGED: "MANUFACTOR_LIST_CHANGED",
	DROP_MENU_MANUFACTOR_VISIBILITY: 'DROP_MENU_MANUFACTOR_VISIBILITY',
	DROP_MENU_MANUFACTOR_TOGGLE: 'DROP_MENU_MANUFACTOR_TOGGLE',

	REQUEST_COUNT_CHANGED: 'REQUEST_COUNT_CHANGED',
	REQUEST_COMPLETE: 'REQUEST_COMPLETE',
	PRODUCT_LIST_CHANGED: 'PRODUCT_LIST_CHANGED',

	GET_BASE_MODEL_COMPLETE: 'GET_BASE_MODEL_COMPLETE',
	MC_TREE_CHANGED: 'MC_TREE_CHANGED',
	CATEGORY_TREE_CHANGED: 'CATEGORY_TREE_CHANGED',
	PRODUCT_TYPE_CHANGED: 'PRODUCT_TYPE_CHANGED',
	SEARCH_BOX_TOGGLE: 'SEARCH_BOX_TOGGLE',
	SEARCH_BOX_CHANGED: 'SEARCH_BOX_CHANGED',
	ORDER_CHANGED: 'ORDER_CHANGED',
	CUSTOMER_CHANGED: 'CUSTOMER_CHANGED',
	SEARCH_PRODUCT_TEXT_CHANGED: 'SEARCH_PRODUCT_TEXT_CHANGED',
	SET_SEARCH_PRODUCT_TEXT: 'SET_SEARCH_PRODUCT_TEXT',
	SEARCH_RESULT_CHANGED: 'SEARCH_RESULT_CHANGED',
	GET_ORDER_COMPLETE: 'GET_ORDER_COMPLETE',
	PRODUCT_CHANGED: 'PRODUCT_CHANGED',
	SELECT_VIEWTYPE: 'SELECT_VIEWTYPE',
	VIEWTYPE_CHANGED: 'VIEWTYPE_CHANGED',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_REQUEST_COMPLETE: 'LOGIN_REQUEST_COMPLETE',
	LOGIN_SUCCEED: 'LOGIN_SUCCEED',
	LOGIN_FAILED: 'LOGIN_FAILED',
	LOGOUT: 'LOGOUT',
	LOGIN_STATUS_CHANGED: 'LOGIN_STATUS_CHANGED',
	SET_LOGIN_MESSAGE: 'SET_LOGIN_MESSAGE',
	LOGIN_MESSAGE_CHANGED: 'LOGIN_MESSAGE_CHANGED',
	SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
	ERROR_MESSAGE_CHANGED: 'ERROR_MESSAGE_CHANGED',
	SET_ORDER_ID: 'SET_ORDER_ID',
	NEW_ORDER_REQUEST: 'NEW_ORDER_REQUEST',
	ORDER_REQUEST_COMPLETE: 'ORDER_REQUEST_COMPLETE',
	SELECT_PRODUCT_BY_ID: 'SELECT_PRODUCT_BY_ID',
	CUSTOMER_REQUEST_COMPLETE: 'CUSTOMER_REQUEST_COMPLETE',
	PASSWORD_CHANGED: 'PASSWORD_CHANGED',
	GET_PRODUCT_COMPLETE: 'GET_PRODUCT_COMPLETE',
	GET_PRODUCT_READMORE_COMPLETE: 'GET_PRODUCT_READMORE_COMPLETE',
	GET_PRODUCT_FROM_URL_COMPLETE: 'GET_PRODUCT_FROM_URL_COMPLETE',
	NEW_PRODUCT_REQUEST: 'NEW_PRODUCT_REQUEST',
	PRODUCT_REQUEST_COMPLETE: 'PRODUCT_REQUEST_COMPLETE',
	SELECT_PRODUCT: 'SELECT_PRODUCT',
	SET_FULLSIZE_IMAGE: 'SET_FULLSIZE_IMAGE',
	FULLSIZE_IMAGE_CHANGED: 'FULLSIZE_IMAGE_CHANGED',
	SET_MESSAGE: 'SET_MESSAGE',
	MESSAGE_CHANGED: 'MESSAGE_CHANGED',
	IS_LOGINTOKEN_EXPIRED: 'IS_LOGINTOKEN_EXPIRED',
	LOGINTOKEN_EXPIRED: 'LOGINTOKEN_EXPIRED',
	LOGINTOKEN_WILL_EXPIRE: 'LOGINTOKEN_WILL_EXPIRE',
	SELECT_BIKE_TYPE: 'SELECT_BIKE_TYPE',
	BIKE_TYPE_SELECTED: 'BIKE_TYPE_SELECTED',
	BIKE_TYPE_LIST_CHANGED: 'BIKE_TYPE_LIST_CHANGED',
	GET_START_MODEL_COMPLETE: 'GET_START_MODEL_COMPLETE',
	START_MODEL_CHANGED: 'START_MODEL_CHANGED',
	SET_CUSTOMER_NEWS_VISIBILITY: 'SET_CUSTOMER_NEWS_VISIBILITY',
	CUSTOMER_NEWS_VISIBILITY_CHANGED: 'CUSTOMER_NEWS_VISIBILITY_CHANGED',
	SET_AUTO_NAVIGATE: 'SET_AUTO_NAVIGATE',
	SELECT_MC: 'SELECT_MC',
	MC_CHANGED: 'MC_CHANGED',
	SELECT_YEAR: 'SELECT_YEAR',
	YEAR_CHANGED: 'YEAR_CHANGED',
	UPDATE_URL: 'UPDATE_URL',
	SET_CURRENT_PRODUCT: 'SET_CURRENT_PRODUCT',
	SET_SEARCH_MC_TEXT: 'SET_SEARCH_MC_TEXT',
	SEARCH_MC_TEXT_CHANGED: 'SEARCH_MC_TEXT_CHANGED',
	SET_SEARCH_MANUFACTOR_TEXT: 'SET_SEARCH_MANUFACTOR_TEXT',
	HIDE_PRODUCT: 'HIDE_PRODUCT',
	SELECT_PRODUCT_DONT_UPDATE_URL: 'SELECT_PRODUCT_DONT_UPDATE_URL',
	//HISTORY_LIST_CHANGED:'HISTORY_LIST_CHANGED',

	SHOW_HISTORY: 'SHOW_HISTORY',
	ADD_TO_HISTORY: 'ADD_TO_HISTORY',
	HISTORY_LIST_CHANGED: 'HISTORY_LIST_CHANGED',

	ADD_IMPORT_MESSAGE: 'ADD_IMPORT_MESSAGE_ERROR',
	IMPORT_MESSAGE_OK: 'IMPORT_MESSAGE_OK',
	IMPORT_MESSAGE_ERROR: 'IMPORT_MESSAGE_ERROR',
	REMOVE_IMPORT_MESSAGES: 'REMOVE_IMPORT_MESSAGES',
	IMPORT_MESSAGES_CHANGED: 'IMPORT_MESSAGES_CHANGED',
	IMPORT_ONE_PRODUCT_COMPLETE: 'IMPORT_ONE_PRODUCT_COMPLETE',

	PAGE_TYPE_START: 'START',
	PAGE_TYPE_ORDER: 'ORDER',
	PAGE_TYPE_MOTUL_SEARCH: 'MOTUL-SEARCH',
	PAGE_TYPE_PRODUCTS: 'PRODUCTS',

	SORT_PRICE_DESC: 'Pris, dyraste först',
	SORT_PRICE_ASC: 'Pris, billigaste först',
	SORT_RELEVANCE: 'Relevans',
	SORT_NAME: 'Artikelnamn',
	SORT_ARTNR: 'Artikelnummer',
	SORT_RECENT: 'Nyaste överst',

	GROUP_BY_MANUFACTOR: 'Varumärke',

	SET_SORT_BY: 'SET_SORT_BY',
	SORT_BY_CHANGED: 'SORT_BY_CHANGED',

	SET_GRUOP_BY: 'SET_GRUOP_BY',
	GROUP_BY_CHANGED: 'GROUP_BY_CHANGED',

	SET_ONLY_STOCK: 'SET_ONLY_STOCK',
	ONLY_STOCK_CHANGED: 'ONLY_STOCK_CHANGED',
	SET_ONLY_OUTLET: 'SET_ONLY_OUTLET',
	ONLY_OULET_CHANGED: 'ONLY_OUTLET_CHANGED',
	SHOW_ALL_PRODUCTS: 'SHOW_ALL_PRODUCTS',
	APPEND_PRODUCT_LIST: 'APPEND_PRODUCT_LIST',
	HIDE_ALL_DROPDOWN: 'HIDE_ALL_DROPDOWN',
	PRODUCT_DETAILS_HIDE_SPINNER: 'PRODUCT_DETAILS_HIDE_SPINNER',
	ENABLE_DEBUGMODE: 'ENABLE_DEBUGMODE',

	MOTUL_COMPLETE: 'MOTUL_COMPLETE',
	MOTUL_CHANGED: 'MOTUL_CHANGED'

};