import React from "react"
import DataStore from "../stores/DataStore"
import { NavLink } from 'react-router-dom'

import * as DataActions from "../actions/DataActions"
import * as ThemeManager from "../ThemeManager"
import StartProductType from "./start/StartProductType";
import StartCategoryView from "./start/StartCategoryView";
import StartNavigation from "./start/StartNavigation";
import SlideList from "./slide/SlideList";

import Build from "./Build";

var Constants = require('../Constants')

export default class News extends React.Component {

	constructor() {
		super();
		this.state = {
			requestCount: DataStore.getRequestCount(),
			startModel: DataStore.getStartModel(),
			productType: DataStore.getProductType(),
			counter: 0
		}

		console.log("FP: URL: News()")
		//console.log("FP: URL: ", DataStore.filterProductList());

		DataActions.hideProduct();
	}

	styleTitle = {
		color: 'white',
		display: 'inline'

	};

	buttonStyle = {
		marginRight: '10px'
	}

	componentDidMount() {

		DataStore.on(Constants.REQUEST_COUNT_CHANGED, () => {
			this.setState({ requestCount: DataStore.getRequestCount() })
		})

		DataStore.on(Constants.START_MODEL_CHANGED, () => {
			this.setState({ startModel: DataStore.getStartModel() })

		})

	}


	onThemeAClick() {
		ThemeManager.themeA();
	}

	onThemeBClick() {
		ThemeManager.themeB();
	}

	onBooveClick() {

		this.setState({ counter: this.state.counter + 1 })

		if (this.state.counter > 5) {
			this.setState({ counter: 0 });

			if (!window.confirm("Enable debugmode")) {
				return;
			}

			DataActions.enableDebugMode();
		}
	}

	productType = param => e => {
		DataActions.selectProductType(param)
		this.setState({ productType: param });
	};

	render() {
		/*
						if (this.state.productType > 0){
		
								if (this.state.productType == 1){
										const url = "/mc/products/list/";
										return <Redirect push to={url} />
								}
							  
								if (this.state.productType == 2){
										const url = "/cykel/products/list/";
										return <Redirect push to={url} />
								}
		
								if (this.state.productType == 3){
										const url = "/motul/products/list/";
										return <Redirect push to={url} />
								}
						}*/

		const css = this.props.requestCount == 0 ? "navigationItem" : "navigationItem loading";
		const loading = this.props.requestCount == 0;

		const showSlideList = this.state.startModel && this.state.startModel.showSlideList

		return (



			<div className='startPage'>

				<div className='navigation'>
					<StartNavigation />
				</div>
				{showSlideList ? <SlideList type="NYHETER" /> : null}
				<div className='startContent'>



					{this.state.startModel ?
						<div>

							{this.state.startModel.boove.header && this.state.startModel.boove.header.length > 10 ?
								<div className='startContentHeader' dangerouslySetInnerHTML={{ __html: this.state.startModel.boove.header }}></div> : null}

							<div className='productTypeList'>

								{this.props.productType == null ?
									<div>
										<StartProductType productType={this.state.startModel.mc} visible={true} />
										<StartProductType productType={this.state.startModel.bike} visible={true} />
										<StartCategoryView categoryView={this.state.startModel.motul} visible={true} />
									</div> : null}

								{this.props.productType == 1 ?
									<div>
										<StartProductType productType={this.state.startModel.mc} visible={true} />
										<StartCategoryView categoryView={this.state.startModel.motul} visible={false} />
										<StartProductType productType={this.state.startModel.bike} visible={false} />

									</div> : null}

								{this.props.productType == 2 ?
									<div>
										<StartProductType productType={this.state.startModel.bike} visible={true} />
										<StartProductType productType={this.state.startModel.mc} visible={false} />
										<StartCategoryView categoryView={this.state.startModel.motul} visible={false} />
									</div> : null}

								{this.props.productType == 3 ?
									<div>
										<StartCategoryView categoryView={this.state.startModel.motul} visible={true} />
										<StartProductType productType={this.state.startModel.bike} visible={false} />
										<StartProductType productType={this.state.startModel.mc} visible={false} />

									</div> : null}

							</div>
							{true ?
								<div className='retailView'>
									<h1><a href='/bli-af/'>Bli återförsäljare av våra produkter!</a></h1>


								</div> : null}


						</div>


						: null}












				</div>

				{this.state.startModel ?
					<div className='aboutView' >
						<a name='boove'></a>
						<h1>Kontakta Boove</h1>
						<div className='about desktop' dangerouslySetInnerHTML={{ __html: this.state.startModel.boove.contactDesktop }}></div>
						<div className='about mobile' dangerouslySetInnerHTML={{ __html: this.state.startModel.boove.contactMobile }}></div>
						<br />
						<h1 onClick={this.onBooveClick.bind(this)}>Om Boove</h1>
						<div className='about' dangerouslySetInnerHTML={{ __html: this.state.startModel.boove.about }}></div>
						<hr />
						<div className='footer' dangerouslySetInnerHTML={{ __html: this.state.startModel.boove.footer }}></div>

						<Build />


					</div> : null}
			</div>
		)
	}
}

/*      <button onClick={this.onThemeAClick.bind(this)}>DARK MODE</button>
                        <button onClick={this.onThemeBClick.bind(this)}>LIGHT MODE</button>*/